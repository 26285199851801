<template>
  <div class="loginPage bgfff">
    <div class="loginBox h5MaxWidth display alignCenter">
      <div class="login forms">
        <div class="topArea">
          <div class="logo"><img src="../../images/logo.svg" alt="" /></div>
          <div class="greetings"></div>
        </div>
        <form id="login" action="#" method="post" onsubmit="">
          <ul>
            <li class="areaCode">
              <div class="inputArea display alignCenter">
                <div class="trueInpt display alignCenter flex1">
                  <p id="areaCode"></p>
                  <input
                    type="text"
                    name="mobile"
                    id="mobile"
                    class="mobile fillArea flex1"
                    placeholder="+91"
                    maxlength="11"
                    size="11"
                  />
                </div>
              </div>
            </li>
            <li>
              <div class="inputArea display alignCenter">
                <div class="trueInpt display alignCenter flex1">
                  <input
                    type="password"
                    name="login_password"
                    id="login_password"
                    placeholder="Login password"
                    class="password fillArea eyeClose flex1"
                  />
                  <div
                    id="eye"
                    class="eye secret rightSideBox display alignCenter"
                  >
                    <i class="r iconfont icon-eye"></i>
                    <i class="s iconfont icon-eye-slash"></i>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div class="actionBar display alignCenter justifyDetween">
            <div class="goSignup" @click="goPage('/signUp')">
              SIGN UP
            </div>

            <div
              class="loginBtn clickBtn radius50P display alignCenter justifyCenter"
              @click="login()"
            >
              <p>Login in now</p>
              <i class="iconfont icon-arrow-right-3"></i>
            </div>
          </div>
          <div class="fPassword display alignCenter justifyEnd">
            <a href="javascript:;"  @click="goPage('/resetLoginPassword')" class="display alignCenter"
              >I forgot my password</a
            >
          </div>
        </form>
      </div>

      <div class="burelage">
        <img src="../../images/pexels-photo-6781340.jpeg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {};
  },
  components: {},
  methods: {
    goPage(page){
      this.$router.push(page);
    },
    login() {
      var account = $("#mobile").val();
      var passWord = $("#login_password").val();

      if (account === "") {
        this.toast("", "Mobile phone can not be empty.", "login", 2000, 1);
        $("#mobile").focus();
        return;
      }

      if (passWord === "") {
        this.toast("", "Password can not be empty.", "login", 2000, 1);
        $("#login_password").focus();
        return;
      }

      this.toast("", "LODING...", "loding", 10000, 4);
      this.getAjax(
        "api/login/index",
        {
          mobile: account,
          password: passWord,
        },
        "post"
      ).then((res) => {
        this.removeToast("loding");
        if (res.code === 200) {
          this.setItem("userInfo", res.data);
          this.setItem("uid", res.data.uid);
          sessionStorage.removeItem('showPop2');
          sessionStorage.setItem("tab", 2);
          this.$router.push("/home/mine");
        } else {
          this.toast("", res.msg, "login", 2000, 1);
        }
      });
    },
  },
  mounted() {
    $(document).ready(function () {
      $("#login_password").togglePassword({
        el: "#eye",
      });
    });
  },
};
</script>

<style scoped lang="less">
</style>
